import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Box, List, ListItem, Link, Heading1, Heading2, Text, Divider, Flex } from "@nulogy/components";
import { COPY_WIDTH, CODE_WIDTH } from "../../components/CONSTANTS";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Box m="0 auto" mb="x8" maxWidth={CODE_WIDTH} mdxType="Box">
  <Heading1 mdxType="Heading1">Design principles</Heading1>
  <Text fontSize="24px" mb="x4" mdxType="Text">
    Design principles embody our design philosophy and guide the design decision
    making process.
  </Text>
    </Box>
    <Flex maxWidth={CODE_WIDTH} m="0 auto" mb="x2" alignItems="center" flexDirection={{
      extraSmall: "column",
      small: "row",
      medium: "column",
      large: "row"
    }} mdxType="Flex">
  <Flex mb={{
        extraSmall: "0",
        small: "x2",
        medium: "0",
        large: "x2"
      }} flexDirection="row" alignItems="stretch" width="100%" maxWidth={{
        large: "460px"
      }} mdxType="Flex">
    <Flex mr={{
          extraSmall: "x2",
          small: "x3",
          medium: "x2",
          large: "x3"
        }} justifyContent="center" alignItems="center" width={{
          extraSmall: "x6",
          small: "x8",
          medium: "x6",
          large: "x8"
        }} textAlign="center" borderRight="solid 1px" borderColor="lightGrey" color="darkGrey" fontSize="small" fontWeight="bold" mdxType="Flex">
      1
    </Flex>
    <Heading2 width="100%" mb="0" pb="x1" fontSize={{
          extraSmall: "40px",
          small: "56px",
          medium: "40px",
          large: "56px"
        }} lineHeight="52px" fontWeight="bold" mdxType="Heading2">
      See the big picture
    </Heading2>
  </Flex>
  <Box width="100%" mdxType="Box">
    <Text mr="x8" mb="x4" ml="x8" mdxType="Text">
      Keep the platform in mind. No matter how small the design decision,
      consider the strategy and larger impacts on the entire platform.
    </Text>
    <List mr="x8" mb="x6" ml="x5" fontSize="small" mdxType="List">
      <ListItem mdxType="ListItem">
        Consider the impact of the design change on all user types beyond the
        primary role.
      </ListItem>
      <ListItem mdxType="ListItem">
        Seek out opportunities for collaboration between users through our
        products.
      </ListItem>
      <ListItem mdxType="ListItem">
        Consider the impact of your work on the larger market.
      </ListItem>
      <ListItem mdxType="ListItem">
        Refer to existing patterns and internal guidelines to ensure consistency
        of UX across the platform.
      </ListItem>
    </List>
  </Box>
    </Flex>
    <Divider mb="x6" maxWidth={CODE_WIDTH} mdxType="Divider" />
    <Flex maxWidth={CODE_WIDTH} m="0 auto" mb="x2" alignItems="center" flexDirection={{
      extraSmall: "column",
      small: "row",
      medium: "column",
      large: "row"
    }} mdxType="Flex">
  <Flex mb={{
        extraSmall: "0",
        small: "x2",
        medium: "0",
        large: "x2"
      }} flexDirection="row" alignItems="stretch" width="100%" maxWidth={{
        large: "460px"
      }} mdxType="Flex">
    <Flex mr={{
          extraSmall: "x2",
          small: "x3",
          medium: "x2",
          large: "x3"
        }} justifyContent="center" alignItems="center" width={{
          extraSmall: "x6",
          small: "x8",
          medium: "x6",
          large: "x8"
        }} textAlign="center" borderRight="solid 1px" borderColor="lightGrey" color="darkGrey" fontSize="small" fontWeight="bold" mdxType="Flex">
      2
    </Flex>
    <Heading2 width="100%" mb="0" pb="x1" fontSize={{
          extraSmall: "40px",
          small: "56px",
          medium: "40px",
          large: "56px"
        }} lineHeight="52px" fontWeight="bold" mdxType="Heading2">
      Design iteratively
    </Heading2>
  </Flex>
  <Box width="100%" mdxType="Box">
    <Text mr="x8" mb="x4" ml="x8" mdxType="Text">
      Commit to continuous improvement. Implement where you can to generate
      feedback on which to improve.
    </Text>
    <List mr="x8" mb="x6" ml="x5" fontSize="small" mdxType="List">
      <ListItem mdxType="ListItem">
        Seek out validation of design assumptions through a cycle of
        implementation and user feedback.
      </ListItem>
      <ListItem mdxType="ListItem">
        Develop strategies to collect data with which to evolve and improve
        implemented solutions.
      </ListItem>
      <ListItem mdxType="ListItem">
        Provide the user with incremental improvements, rather than perfecting a
        design and shipping it all at once.
      </ListItem>
      <ListItem mdxType="ListItem">
        Advocate for the incremental improvements of the design solution.
      </ListItem>
    </List>
  </Box>
    </Flex>
    <Divider mb="x6" maxWidth={CODE_WIDTH} mdxType="Divider" />
    <Flex maxWidth={CODE_WIDTH} m="0 auto" mb="x2" alignItems="center" flexDirection={{
      extraSmall: "column",
      small: "row",
      medium: "column",
      large: "row"
    }} mdxType="Flex">
  <Flex mb={{
        extraSmall: "0",
        small: "x2",
        medium: "0",
        large: "x2"
      }} flexDirection="row" alignItems="stretch" width="100%" maxWidth={{
        large: "460px"
      }} mdxType="Flex">
    <Flex mr={{
          extraSmall: "x2",
          small: "x3",
          medium: "x2",
          large: "x3"
        }} justifyContent="center" alignItems="center" width={{
          extraSmall: "x6",
          small: "x8",
          medium: "x6",
          large: "x8"
        }} textAlign="center" borderRight="solid 1px" borderColor="lightGrey" color="darkGrey" fontSize="small" fontWeight="bold" mdxType="Flex">
      3
    </Flex>
    <Heading2 width="100%" mb="0" pb="x1" fontSize={{
          extraSmall: "40px",
          small: "56px",
          medium: "40px",
          large: "56px"
        }} lineHeight="52px" fontWeight="bold" mdxType="Heading2">
      Empower users
    </Heading2>
  </Flex>
  <Box width="100%" mdxType="Box">
    <Text mr="x8" mb="x4" ml="x8" mdxType="Text">
      Give users full control and transparency. Provide the user with timely and
      relevant information that empowers them with the tools they need to move
      forward.
    </Text>
    <List mr="x8" mb="x6" ml="x5" fontSize="small" mdxType="List">
      <ListItem mdxType="ListItem">
        Leverage in-app training, help, and supporting documentation.
      </ListItem>
      <ListItem mdxType="ListItem">Provide transparency of automated processes.</ListItem>
      <ListItem mdxType="ListItem">
        Build trust through timely and relevant recommendations.
      </ListItem>
      <ListItem mdxType="ListItem">
        When designing, consider varying levels of user proficiency and
        different user needs.
      </ListItem>
    </List>
  </Box>
    </Flex>
    <Divider mb="x6" maxWidth={CODE_WIDTH} mdxType="Divider" />
    <Flex maxWidth={CODE_WIDTH} m="0 auto" mb="x2" alignItems="center" flexDirection={{
      extraSmall: "column",
      small: "row",
      medium: "column",
      large: "row"
    }} mdxType="Flex">
  <Flex mb={{
        extraSmall: "0",
        small: "x2",
        medium: "0",
        large: "x2"
      }} flexDirection="row" alignItems="stretch" width="100%" maxWidth={{
        large: "460px"
      }} mdxType="Flex">
    <Flex mr={{
          extraSmall: "x2",
          small: "x3",
          medium: "x2",
          large: "x3"
        }} justifyContent="center" alignItems="center" width={{
          extraSmall: "x6",
          small: "x8",
          medium: "x6",
          large: "x8"
        }} textAlign="center" borderRight="solid 1px" borderColor="lightGrey" color="darkGrey" fontSize="small" fontWeight="bold" mdxType="Flex">
      4
    </Flex>
    <Heading2 width="100%" mb="0" pb="x1" fontSize={{
          extraSmall: "40px",
          small: "56px",
          medium: "40px",
          large: "56px"
        }} lineHeight="52px" fontWeight="bold" mdxType="Heading2">
      Promote collaboration
    </Heading2>
  </Flex>
  <Box width="100%" mdxType="Box">
    <Text mr="x8" mb="x4" ml="x8" mdxType="Text">
      Pursue collaboration within the design team, the company, and with
      customers. Multiple perspectives make better products.
    </Text>
    <List mr="x8" mb="x6" ml="x5" fontSize="small" mdxType="List">
      <ListItem mdxType="ListItem">
        Maintain a positive and productive work relationship with your Product
        Manager.
      </ListItem>
      <ListItem mdxType="ListItem">
        Seek out opportunities to include your developers in the design process.
      </ListItem>
      <ListItem mdxType="ListItem">
        Establish and nurture partnerships with clients and subject matter
        experts.
      </ListItem>
      <ListItem mdxType="ListItem">
        Contribute to a shared understanding by documenting knowledge.
      </ListItem>
    </List>
  </Box>
    </Flex>
    <Divider mb="x6" maxWidth={CODE_WIDTH} mdxType="Divider" />
    <Flex maxWidth={CODE_WIDTH} m="0 auto" mb="x2" alignItems="center" flexDirection={{
      extraSmall: "column",
      small: "row",
      medium: "column",
      large: "row"
    }} mdxType="Flex">
  <Flex mb={{
        extraSmall: "0",
        small: "x2",
        medium: "0",
        large: "x2"
      }} flexDirection="row" alignItems="stretch" width="100%" maxWidth={{
        large: "460px"
      }} mdxType="Flex">
    <Flex mr={{
          extraSmall: "x2",
          small: "x3",
          medium: "x2",
          large: "x3"
        }} justifyContent="center" alignItems="center" width={{
          extraSmall: "x6",
          small: "x8",
          medium: "x6",
          large: "x8"
        }} textAlign="center" borderRight="solid 1px" borderColor="lightGrey" color="darkGrey" fontSize="small" fontWeight="bold" mdxType="Flex">
      5
    </Flex>
    <Heading2 width="100%" mb="0" pb="x1" fontSize={{
          extraSmall: "40px",
          small: "56px",
          medium: "40px",
          large: "56px"
        }} lineHeight="52px" fontWeight="bold" mdxType="Heading2">
      Think beyond design
    </Heading2>
  </Flex>
  <Box width="100%" mdxType="Box">
    <Text mr="x8" mb="x4" ml="x8" mdxType="Text">
      Seek out a deep understanding of our industry, users, and business and use
      cases in order to better shape product solutions.
    </Text>
    <List mr="x8" mb="x6" ml="x5" fontSize="small" mdxType="List">
      <ListItem mdxType="ListItem">
        Invest in early stages of research to establish a deep understanding of
        the problem space.
      </ListItem>
      <ListItem mdxType="ListItem">
        Understand the competing priorities of different disciplines within
        Nulogy.
      </ListItem>
    </List>
  </Box>
    </Flex>
    <Divider mb="x6" maxWidth={CODE_WIDTH} mdxType="Divider" />
    <Flex maxWidth={CODE_WIDTH} m="0 auto" mb="x2" alignItems="center" flexDirection={{
      extraSmall: "column",
      small: "row",
      medium: "column",
      large: "row"
    }} mdxType="Flex">
  <Flex mb={{
        extraSmall: "0",
        small: "x2",
        medium: "0",
        large: "x2"
      }} flexDirection="row" alignItems="stretch" width="100%" maxWidth={{
        large: "460px"
      }} mdxType="Flex">
    <Flex mr={{
          extraSmall: "x2",
          small: "x3",
          medium: "x2",
          large: "x3"
        }} justifyContent="center" alignItems="center" width={{
          extraSmall: "x6",
          small: "x8",
          medium: "x6",
          large: "x8"
        }} textAlign="center" borderRight="solid 1px" borderColor="lightGrey" color="darkGrey" fontSize="small" fontWeight="bold" mdxType="Flex">
      6
    </Flex>
    <Heading2 width="100%" mb="0" pb="x1" fontSize={{
          extraSmall: "40px",
          small: "56px",
          medium: "40px",
          large: "56px"
        }} lineHeight="52px" fontWeight="bold" mdxType="Heading2">
      Value great design
    </Heading2>
  </Flex>
  <Box width="100%" mdxType="Box">
    <Text mr="x8" mb="x4" ml="x8" mdxType="Text">
      Great products are accessible, efficient, delightful, and easy to learn.
      Strike the right balance that matches the product's current maturity stage
      and work towards normalizing the balance across our products.
    </Text>
    <List mr="x8" mb="x6" ml="x5" fontSize="small" mdxType="List">
      <ListItem mdxType="ListItem">
        Comply with WCAG 2.1. AA accessibility guidelines within your control.
      </ListItem>
      <ListItem mdxType="ListItem">
        Optimize experiences for specific needs based on supporting data and
        research.
      </ListItem>
      <ListItem mdxType="ListItem">
        Make the extra effort to create delightful experiences to help drive
        adoption.
      </ListItem>
    </List>
  </Box>
    </Flex>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      